<template>
  <div class="flex flex-col space-y-4">
    <div :class="`border mx-auto grid grid-cols-${size.width} grid-rows-${size.height}`">
      <Cell v-for="(cell, index) in flatGrid" :key="index" :activated="cell" v-on:switch="$emit('switch', index)" />
    </div>
    <button @click="$emit('clear')" type="button" class="inline-flex mx-auto items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Clear grid
    </button>
  </div>
</template>

<script>
import Cell from '@/components/Cell.vue'

export default {
  name: 'Grid',
  components: {
    Cell
  },
  props: {
    grid: {
      required: true,
      type: Object
    },
    size: {
      required: true,
      type: Object
    }
  },
  computed: {
    flatGrid () {
      return this.grid.flat()
    }
  }
}
</script>
