<template>
  <div class="flex flex-col items-center justify-center space-y-8">
    <div class="flex space-x-8">
      <button @click="randomGrid" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Random start grid
      </button>
      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col mr-4">
          <span class="text-sm font-medium text-gray-900" id="availability-label">Update automatic</span>
          <span class="text-sm text-gray-500" id="availability-description">Update the following grids automatically</span>
        </span>
        <button @click="automatic = !automatic" type="button" :class="automatic ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false" aria-labelledby="availability-label" aria-describedby="availability-description">
          <span aria-hidden="true" :class="automatic ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>
      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col mr-4">
          <span class="text-sm font-medium text-gray-900" id="availability-label">Wrap around</span>
          <span class="text-sm text-gray-500" id="availability-description">Whether or not to wrap around the grid</span>
        </span>
        <button @click="wrapAround = !wrapAround" type="button" :class="wrapAround ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false" aria-labelledby="availability-label" aria-describedby="availability-description">
          <span aria-hidden="true" :class="wrapAround ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>
    </div>
    <div class="grid grid-cols-4 space-x-4">
      <Grid v-for="(grid, index) in grids" :key="index" :grid="grid" :size="size" v-on:switch="switchCell(index, $event)" v-on:clear="clearGrid(index)" />
    </div>
  </div>
</template>

<script>
import Grid from '@/components/Grid'

export default {
  name: 'Game',
  components: {
    Grid
  },
  data: () => ({
    numberOfGrids: 4,
    automatic: false,
    wrapAround: false,
    size: {
      width: 5,
      height: 4
    },
    grids: []
  }),
  mounted () {
    this.grids = Array(this.numberOfGrids).fill(0).map(() => Array(this.size.height).fill(0).map(() => Array(this.size.width).fill(false)))
  },
  methods: {
    switchCell (grid, cell) {
      console.log(grid, cell, cell % this.size.width, Math.floor(cell / this.size.width))
      this.grids[grid][Math.floor(cell / this.size.width)][cell % this.size.width] = !this.grids[grid][Math.floor(cell / this.size.width)][cell % this.size.width]

      if (this.automatic) this.updateGrids()
    },
    updateGrids () {
      let prevGrid = this.grids[0]

      for (let i = 1; i < this.grids.length; i++) {
        let grid = this.grids[i]

        for (let y = 0; y < grid.length; y++) {
          for (let x = 0; x < grid[y].length; x++) {
            let neighbours = 0

            for (let dy = -1; dy <= 1; dy++) {
              for (let dx = -1; dx <= 1; dx++) {
                if (dx === 0 && dy === 0) continue

                let nx = x + dx
                let ny = y + dy

                if (!this.wrapAround) {
                  if (nx < 0 || nx >= grid[y].length || ny < 0 || ny >= grid.length) continue
                  if (prevGrid[ny][nx]) neighbours++
                } else {
                  if (nx < 0) nx += grid[y].length
                  if (nx >= grid[y].length) nx -= grid[y].length
                  if (ny < 0) ny += grid.length
                  if (ny >= grid.length) ny -= grid.length
                  if (prevGrid[ny][nx]) neighbours++
                }
              }
            }

            /* Game of life rules */
            // if (neighbours === 3) grid[y][x] = true
            // else if (neighbours === 2) grid[y][x] = prevGrid[y][x]
            // else grid[y][x] = false

            /* Simple rules */
            if (neighbours % 2 == 0) grid[y][x] = true
            else grid[y][x] = false
          }
        }

        prevGrid = grid
      }
    },
    randomGrid () {
      for (let j = 0; j < this.grids[0].length; j++) {
        for (let k = 0; k < this.grids[0][j].length; k++) {
          this.grids[0][j][k] = Math.random() > 0.5
        }
      }

      if (this.automatic) this.updateGrids()
    },
    clearGrid (grid) {
      for (let j = 0; j < this.grids[grid].length; j++) {
        for (let k = 0; k < this.grids[grid][j].length; k++) {
          this.grids[grid][j][k] = false
        }
      }

      if (this.automatic) this.updateGrids()
    }
  },
  watch: {
    automatic: {
      handler () {
        if (this.automatic) this.updateGrids()
      }
    },
    wrapAround: {
      handler () {
        if (this.automatic) this.updateGrids()
      }
    }
  }
}
</script>
