<template>
  <div class="h-screen w-screen p-4 flex flex-col space-y-8 justify-center">
    <Game v-for="index in numberOfGames" :key="index" />
  </div>
</template>

<script>
import Game from '@/components/Game'

export default {
  name: 'App',
  components: {
    Game
  },
  data: () => ({
    numberOfGames: 2
  })
}
</script>
