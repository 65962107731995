<template>
  <div @click="clicked()" :class="[activated ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-100', 'h-20 w-20 border cursor-pointer']" />
</template>

<script>
export default {
  name: 'Cell',
  props: {
    activated: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  methods: {
    clicked () {
      this.$emit('switch')
    }
  }
}
</script>
